import * as React from "react";

import { LinkList, SEO } from "@parataxic/shared-ui";

import { PopoverSiteLink } from "../components/Popover";
import { DownloadLinks } from "./PDFList";

interface IProps {
  data: GatsbyTypes.PdfMediaListQuery;
  pageContext: { pdf: GatsbyTypes.PdfMediaItemFragment };
}

const PdfItem: React.FC<{
  pdf: GatsbyTypes.PdfMediaItemFragment;
}> = ({ pdf }) => {
  const { mentionedTheories } = pdf;
  return (
    <span style={{ width: "100%" }}>
      <h3
        style={{
          paddingBottom: "1rem",
        }}
      >
        {pdf.title} {pdf.createdAt && `(${pdf.createdAt})`}
      </h3>
      {pdf.description && (
        <span style={{ paddingBottom: "1rem" }}>{pdf.description}</span>
      )}
      <div style={{ display: "grid", gridTemplateColumns: "3" }}>
        <div style={{ alignSelf: "center" }}>
          <span>
            <LinkList links={pdf.creators} Component={PopoverSiteLink} />
          </span>
        </div>
        <div style={{ alignSelf: "center" }}>
          <LinkList links={mentionedTheories} Component={PopoverSiteLink} />
        </div>
        <div style={{ textAlign: "right" }}>
          <DownloadLinks
            pdfLinks={pdf.pdfLinks}
            LinkComponent={({ children }) => (
              <span
                style={{
                  backgroundColor: "var(--colors-blue-700)",
                  color: "white",
                  padding: "1rem",
                }}
              >
                {children}
              </span>
            )}
            linkProps={{
              style: {
                marginRight: "1rem",
              },
            }}
          />
        </div>
      </div>
    </span>
  );
};

const View: React.FC<IProps> = ({ pageContext: { pdf } }) => {
  const seoProps: { description?: string } = {};
  if (pdf.description) {
    seoProps.description = pdf.description;
  }

  return (
    <>
      <SEO title={pdf.title} {...seoProps} />
      <div
        style={{
          flexDirection: "row",
          textAlign: "center",
          padding: "1rem 0",
          borderColor: "var(--color-gray-200)",
        }}
        role="article"
      >
        <PdfItem pdf={pdf} />
      </div>
    </>
  );
};

export default View;
